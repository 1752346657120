*,
*::before,
*::after {
  box-sizing: border-box;
}

.img-slider-img {
  /* object-fit: cover; */
  width: 100%;
  height: 100%;
  display: block;
  flex-shrink: 0;
  flex-grow: 0;
  transition: translate 300ms ease-in-out;
}

.img-slider-btn {
  all: unset;
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  padding: 1rem;
  cursor: pointer;
  /* transition: background-color 100ms ease-in-out; */
}

/* .img-slider-btn:hover {
  background-color: rgb(0, 0, 0, 0.2);
} */

/* .img-slider-btn:hover > * {
  animation: squish 200ms ease-in-out;
} */

.img-slider-btn > * {
  stroke: white;
  fill: black;
  width: 2rem;
  height: 2rem;
}

/* @keyframes squish {
  50% {
    scale: 1.4 0.6;
  }
} */

.img-slider-nav-btn {
  all: unset;
  display: block;
  cursor: pointer;
  /* width: 1rem;
  height: 1rem; */
}

/* .img-slider-nav-btn > * {
  stroke: white;
  fill: black;
  height: 100%;
  width: 100%;
} */

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}
