@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  @apply bg-white;
}
/* 
@font-face {
  font-family: "Roboto";
  src: local("Roboto"),
    url(./assets/fonts/Roboto/Roboto-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "Inria Sans", sans-serif;
  src: local("Inria Sans"),
    url(./assets/fonts/Inria_Sans/InriaSans-Regular.ttf) format("ttf");
}

@font-face {
  font-family: "Roboto Condensed", sans-serif;
  src: local("Roboto Condensed"),
    url(./assets/fonts/Roboto_Condensed/RobotoCondensed-Regular.ttf)
      format("ttf");
} */

/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}

/* Toggle B */
input:checked ~ .dot {
  transform: translateX(100%);
  background-color: #48bb78;
}
body {
  /* background-color: rgb(250, 250, 250); */
  background-color: #ffffff;
}

/* Tiny MCE Styling*/
figure {
  display: table;
  margin: 1rem auto;
}

figure {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
}

figure.align-left {
  float: left;
}

figure.image.align-left {
  -webkit-user-modify: read-only;
}

figure figcaption {
  color: #999;
  display: block;
  margin-top: 0.25rem;
  text-align: center;
}

p {
  margin-block-end: 4px;
  margin-block-start: 4px;
  margin-inline-end: 0px;
  margin-inline-start: 0px;
  text-align: justify;
  word-wrap: break-word;
}

/* Root background color */
html,
body {
  background-color: white;
  /* margin: 0;
  padding: 0;
  height: 100%;*/
  overscroll-behavior: contain;
}

#root {
  /* min-height: 100vh; */
  background-color: white;
  overscroll-behavior: contain;
}

:root {
  background-color: white;
  overscroll-behavior: contain;
}
