.burger {
  display: flex;
  outline: none;
  /* height: 20px;
  width: 15px; */
  height: 20px;
  width: 20px;
  border: 0px;
  padding: 0px;
  background: transparent;
  transition: all 250ms ease-out;
  cursor: pointer;
}

.burger:before,
.burger:after {
  content: "";
  /* width: 15px;
  height: 2px; */
  width: 14px;
  height: 1px;
  position: absolute;
  /* New */
  top: 1.5rem;
  background: #1a1a1a;
  transition: all 250ms ease-out;
  will-change: transform;
}

.burger:before {
  transform: translateY(-3px);
}

.burger:after {
  transform: translateY(3px);
}

/* line transition */

.active.burger:before {
  transform: translateY(0) rotate(45deg);
}

.active.burger:after {
  transform: translateY(0) rotate(-45deg);
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}
