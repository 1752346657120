/* DemoDrawer.css */
.drawer {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 300px; /* Adjust as needed */
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
  overflow-y: auto;
  z-index: 1000;
}

.drawer-open {
  transform: translateX(0);
}

.drawer-exit {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.drawer-content {
  padding: 20px;
}

.close-button {
  background: red;
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  margin-bottom: 20px;
}
