body::-webkit-scrollbar {
  /* Chrome */
  display: none;

  /* IE (Internet Explorer) and Edge */
  -ms-overflow-style: none;
}

/* Firefox */
html {
  scrollbar-width: none;
}

.noSelect {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.noSelect:focus {
  outline: none !important;
}

.blur-filter {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

/* Root background color */
html,
body {
  background-color: white;
  /* margin: 0;
  padding: 0;
  height: 100%;*/
  overscroll-behavior: contain;
}

#root {
  /* min-height: 100vh; */
  background-color: white;
  overscroll-behavior: contain;
}

:root {
  background-color: white;
  overscroll-behavior: contain;
}
