.hover-region > .hover-display-block {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
}

.hover-region:hover > .hover-display-block {
  visibility: visible;
  opacity: 1;
}
