/* .loader {
  width: 30px;
  aspect-ratio: 1;
  display: grid;
  -webkit-mask: conic-gradient(from 22deg, #0003, #000);
  mask: conic-gradient(from 22deg, #0003, #000);
  animation: load 1s steps(8) infinite;
}
.loader,
.loader:before {
  --_g: linear-gradient(#49494a 0 0) 50%;
  background: var(--_g) / 34% 8% space no-repeat,
    var(--_g) / 8% 34% no-repeat space;
}
.loader:before {
  content: "";
  transform: rotate(45deg);
}
@keyframes load {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
} */

#bar1 {
  animation-delay: 0s;
}

.bar {
  /* width: 0.6em;
  height: 0.2em; */
  opacity: 0.9;
  /* background-color: #555555; */
  animation: jumping-bars 0.45s linear infinite alternate-reverse;
}

@keyframes jumping-bars {
  0% {
    opacity: 1;
    transform: scaleX(0.3);
  }
  50% {
    opacity: 1;
    transform: scaleX(4);
  }
  100% {
    opacity: 1;
    transform: scaleX(9);
  }
}
