/* SwipeableImageSlider.css */
.carousel_container {
  overflow: hidden;
}

.carousel_items {
  display: flex;
  transition: transform 0.3s ease;
}

.carousel_item {
  flex: 0 0 auto;
  width: 100%;
  overflow: hidden;
}

.carousel_item img {
  width: 100%;
  height: auto;
}

.img-swipe-slider-nav-btn {
  all: unset;
  display: block;
  cursor: pointer;
  /* width: 1rem;
  height: 1rem; */
}
